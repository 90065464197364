import React from 'react'
import './bottombg.css'

const Bottombg = () => {
    return (
        
            <div className="bottom-text">
                <span>
                    We Source Natural, Luxurious <br /> Brazilian Cowhide Rugs & More</span>
            </div>
    )
}

export default Bottombg