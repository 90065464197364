import React from 'react'
import './Para.css'

const Para = () => {
    return (
        <section className='para-section'>
            <div className="para">
                <h3>Brazilian Cowhide Rugs</h3>
                <p>If you’re looking for the perfect complement piece for every space in your home, you’ve come to the right place. Furug specializes in natural Brazilian cowhide rugs, pillows, sheepskins, and other natural home décor items. We work with suppliers from around the world and hand-select the best ethically sourced cowhides available. Our selection of Brazilian cowhide rugs already adds to the visual appeal of many beautiful homes, successful office spaces, and upscale hotels around the country. Browse the complete selection of Furug natural cowhide rugs and other home décor pieces today. For more information on any of the pieces we sell, give us a call today at (615) 852-6215.</p>
            </div>
        </section>

    )
}

export default Para