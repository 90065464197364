
import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Image from './Components/Image/Image';
import Line from './Components/Line/Line';
import New from './Components/New arrival/New';
import Para from './Components/Para/Para';
import Product from './Components/Products/Product';
import Top from './Components/Top/Top';
import Bottombg from './Components/bottom bg/Bottombg';

function App() {
  return (
    <div className="App">
      <Top/>
      <Header/>
      <Image/>
      <Product/>
      <New/>
      <Bottombg/>
      <Para/>
      <Line/>
      <Footer/>
    </div>
  );
}

export default App;
