import React from 'react'
import './New.css'

const New = () => {
  return (
    <div className="new-arrivals">
        <div className="top">
            <span>New Arrivals</span>
        </div>
        <div className="cart">
            <div className="item">
                <img src="https://www.furug.com/cdn/shop/files/MG-6_400x.jpg?v=1687358394" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/files/PWH-4_400x.jpg?v=1687353011" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/files/GRM-46_400x.jpg?v=1687289398" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/products/ZB-12_400x.jpg?v=1649796495" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/files/dustyrose_400x.jpg?v=1686235185" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/products/stitched-kellygreen_400x.jpg?v=1681407095" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/products/IMG_1005_400x.jpg?v=1680295008" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>

            <div className="item">
                <img src="https://www.furug.com/cdn/shop/products/EXL-2_400x.jpg?v=1680113351" alt="" />
                <span>Metallic Gold Cowhide Rug</span>
                <span>$795</span>
            </div>
        </div>
        <div className="top-Buttom">
            <span>View all products</span>
        </div>
    </div>
  )
}

export default New