import React from 'react'
import './Image.css'

const Image = () => {
  return (
    <div className='image'>
        <img src="https://www.furug.com/cdn/shop/files/YGR-7_1800x.jpg?v=1645209694" alt="" className='main-img' />
    </div>
  )
}

export default Image