import React, { useState } from 'react'
import './Header.css'

const Header = () => {

    const [active, setActive] = useState("header-left")

  const navToggle = () =>{
    active ==='header-left' ? setActive('header-left nav_active') : setActive("header-left")
  }
    return (
        <section className="header">
            <div className="Header__Wrapper">
                <div className={active}>
                    <a>COLLECTIONS</a>
                    <a>ABOUT US</a>
                    <a>BLOG</a>
                    <a>FAQ</a>
                    <a>CONTACT</a>
                    <a>WHOLESALE</a>
                    
                </div>

                <div className="logo-image">
                    <img src="logo.avif" alt="" />
                </div>

                <div onClick={navToggle} className="ham" >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>

                <div className="header-right">
                    <a href="">Account</a>
                    <a href="">Search</a>
                    <a href="">Cart (0)</a>
                </div>
            </div>


        </section>
    )
}

export default Header