import React from 'react'
import './Top.css'

const Top = () => {
    return (
        <section className="AnnouncementBar">
            <div className="AnnouncementBar__Wrapper">
                <span>FREE SHIPPING | (615) 852-6215</span>
            </div>
        </section>

    )
}

export default Top