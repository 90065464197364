import React from 'react'
import './Product.css'

const Product = () => {
    return (
        <div className="product-main">
            <div className="product">
                <div className="image-text">
                    <img src="https://www.furug.com/cdn/shop/files/Home_Page_-_Cowhides_1000x.jpg?v=1613608335" alt="" />
                </div>
                <span>Cowhides</span>
                <span>VIEW PRODUCTS</span>
            </div>

            <div className="product">
                <div className="image-text">
                    <img src="https://www.furug.com/cdn/shop/files/Home_Page_-_Sheepskins_1000x.jpg?v=1613608335" alt="" />
                </div>
                <span>Sheepskines</span>
                <span>VIEW PRODUCTS</span>
            </div>

            <div className="product">
                <div className="image-text">
                    <img src="https://www.furug.com/cdn/shop/files/Home_Page_-_Pillows_1500x.JPG?v=1615925847" alt="" />
                </div>
                <span>
                    Pillows</span>
                <span>VIEW PRODUCTS</span>
            </div>

            <div className="product">
                <div className="image-text">
                    <img src="https://www.furug.com/cdn/shop/files/Home_Page_-_Rare_Finds_1500x.jpg?v=1613608338" alt="" />
                </div>
                <span>Rare Finds</span>
                <span>VIEW PRODUCTS</span>
            </div>
        </div>
    )
}

export default Product