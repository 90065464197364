import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-container">

        <div className='first flexcol '>
            <span>FURUG</span>
            <p>Born by chance from the purchase of a sole Brazilian cowhide rug a few years earlier, the love of that single unique piece developed into a passion for distinct luxury home décor that is both functional and artistic.</p>
            <p>Info@Furug.com</p>
            <p>615-852-6215</p>
            <p>414 Baxter Ave Louisville, Kentucky</p>
            <div className="icons">
                <img src="0.png" alt="" />
            </div>
        </div>

        <div className='second flexcol'>
            <span>SHOP</span>
            <span>COWHIDES</span>
            <span>PILLOWS</span>
            <span>SHEEPSKINS</span>
            <span>RARE FINDS</span>
            <span>EXOTIC TAXIDERMY</span>
            <span>COWHIDE SALE</span>
        </div>

        <div className='third flexcol'>
            <span>INFORMATION</span>
            <span>FAQ</span>
            <span>RETURN POLICY</span>
            <span>SHEEPSKINS</span>
            <span>SHIPPING</span>
            <span>CARE</span>
            <span>REVIEWS</span>
            </div>

        <div className='fourth flexcol'>
        <span>Keep in Touch!</span>
        <span>Subscribe to receive updates, access to exclusive deals, and more.</span>
        <input type="text"  placeholder='Enter your email address'/>
        <button>SUBSCRIBE</button>
        </div>
    </div>
  )
}

export default Footer